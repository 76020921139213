import { ActionIcon, Flex, LoadingOverlay, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconArrowLeft, IconClock, IconInfoSquare } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { InvoiceApi } from "../../apis";
import { MultiCollapse } from "../../components/MultiCollapse/multi-collapse";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { InvoiceAttachmentDetailsForm } from "../../components/sales-invoice-details/invoice-attachment-details-form";
import { InvoiceProductDetailsForm } from "../../components/sales-invoice-details/invoice-product-details-form";
import { InvoiceReceiverDetailsForm } from "../../components/sales-invoice-details/invoice-receiver-details-form";
import { InvoiceSenderDetailsForm } from "../../components/sales-invoice-details/invoice-sender-form";
import { InvoicePaidHistory } from "../buyer-invoice/invoice-paid-history";

type IFileItem = {
  name: string;
  description: string;
  url: string;
};

type IFormData = {
  senderStaffId: string | undefined;
  senderBranchId: string | undefined;
  senderAccId: string | undefined;
  receiverBusinessId: string | undefined;
  paymentDate: Date | string | undefined;
  receiverBranchId: string | undefined;
  description: string | undefined;
  discountType: string | undefined;
  discountValue: number;
  shippingAmount: number;
  lines: any[];
  send: boolean;
  attachments: IFileItem[];
  refCode: string;
  orderSalesCode: string | null;
  purchaseSalesCode: string | null;
};

export const ClosedInvoiceDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabKey, setTabKey] = useState<string>("details");
  const INV_CLOSED = usePermission("INV_CLOSED");

  const { theme } = userStyles();

  const { data, isLoading } = useSwr<IFormData>(
    params.id !== "new" && INV_CLOSED.hasAccess ? `/api/invoice/${params.id}` : null,
    params.id !== "new" && INV_CLOSED.hasAccess ? async () => await InvoiceApi.get(`${params.id}`) : null,
    {
      fallback: {
        invoiceStatus: undefined,
        senderStaffId: undefined,
        senderBranchId: undefined,
        senderAccId: undefined,

        receiverBusinessId: undefined,
        paymentDate: undefined,
        receiverBranchId: undefined,

        description: undefined,
        discountType: undefined,
        discountValue: 0,
        shippingAmount: 0,
        lines: [],
        send: false,
        attachments: [],
        refCode: undefined,
        additionalLines: [],
      },
    },
  );
  const tabOptions = [
    {
      children: "Нэхэмжлэх мэдээлэл",
      value: "details",
      disabled: false,
      icon: (key: string) => <IconInfoSquare size={22} color={tabKey === key ? theme.colors.brand[6] : "black"} />,
    },
    {
      children: "Төлөлтийн түүх",
      value: "paid_history",
      disabled: false,
      icon: (key: string) => <IconClock size={22} color={tabKey === key ? theme.colors.brand[6] : "black"} />,
    },
  ];

  if (isLoading) return <LoadingOverlay visible />;

  return (
    <PageLayout
      title={
        <Flex gap={8} align="center">
          <ActionIcon onClick={() => navigate(-1)} variant="outline" size="sm" color="brand">
            <IconArrowLeft />
          </ActionIcon>
          <Text>Нэхэмжлэх дэлгэрэнгүй</Text>
          <Text c="brand">#{data?.refCode}</Text>
        </Flex>
      }
      breadcrumb={breadcrumbs}>
      <Tabs
        onChange={(e: string | null) => {
          if (e) setTabKey(e);
        }}
        value={tabKey}>
        <Tabs.List>
          {tabOptions.map((item: { children: string; disabled: boolean; value: string; icon: any }, index: number) => {
            return (
              <Tabs.Tab color="brand" p={"xs"} value={item.value} key={index} disabled={item.disabled}>
                <Flex align="center" gap={8}>
                  {item.icon(item.value)}
                  <Text fw={500} size={"sm"} c={tabKey === item.value ? "brand" : "black"}>
                    {item.children}
                  </Text>
                </Flex>
              </Tabs.Tab>
            );
          })}
        </Tabs.List>

        <Tabs.Panel value="details" pt="xs">
          {INV_CLOSED.hasAccess ? <MultiCollapse loading={isLoading} defaultCollapse={[0, 1, 2, 3, 4]} option={option({ data })} /> : INV_CLOSED.accessDenied()}
        </Tabs.Panel>
        <Tabs.Panel value="paid_history" pt="xs">
          {INV_CLOSED.hasAccess ? <InvoicePaidHistory data={data} /> : INV_CLOSED.accessDenied()}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const option = ({ data }: any) => [
  {
    title: "Нэхэмжлэх илгээж буй тал",
    subTitle: "Нэхэмжлэх илгээж буй партнер байгууллагын мэдээлэл",
    children: <InvoiceSenderDetailsForm values={data} />,
  },
  {
    title: "Худалдан авагч",
    subTitle: "Нэхэмжлэхийн төлбөр төлөгч байгууллагын мэдээлэл",
    children: <InvoiceReceiverDetailsForm values={data} />,
  },
  {
    title: "Нэхэмжлэхийн зүйл",
    subTitle: "Нэхэмжлэхийн зүйл, дэлгэрэнгүй мэдээлэл",
    children: <InvoiceProductDetailsForm values={data} />,
  },
  {
    title: "Хавсралт файлууд",
    subTitle: "Хавсаргасан файлын жагсаалт, дэлгэрэнгүй мэдээлэл",
    children: <InvoiceAttachmentDetailsForm values={data} />,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/sales-invoice",
    label: "Манай авах",
  },
  {
    label: "Нэхэмжлэлийн дэлгэрэнгүй",
  },
];

const userStyles = createStyles(() => ({}));
