import { Avatar, Button, CloseButton, Divider, Flex, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { forwardRef, useRef, useState } from "react";
import useSwr from "swr";
import * as yup from "yup";
import { ErpInvoiceApi } from "../../apis";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { PageLayout } from "../layout";

const schema = yup.object({
  variantId: yup.string().required("Заавал бөглөнө!").nullable(),
  quantity: yup.number().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  item: any;
}

export function InvoiceProductItemForm({ action, setFieldValue, setAction, values }: any) {
  const [query, setQuery] = useState<string | any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const refForm = useRef<any>(null);

  const { data: variantList = [] } = useSwr(
    `/erp/invoice/variant/select?query=${query}`,
    async () => await ErpInvoiceApi.variant_select({ query: query, businessId: values?.receiverBusinessId }),
    {
      fallback: [],
    },
  );

  const [data] = useState<any>({
    variantId: undefined,
    refCode: undefined,
    skuCode: undefined,
    barCode: undefined,
    erpCode: undefined,
    nameMon: undefined,
    nameEng: undefined,
    nameBill: undefined,
    nameWeb: undefined,
    nameApp: undefined,

    image: undefined,
    hasVat: undefined,
    vatPercent: undefined,
    hasTax: undefined,
    taxPercent: undefined,
    category: undefined,
    supplier: undefined,
    brand: undefined,
    price: undefined,
    priceStartDate: undefined,
    unit: undefined,

    vatAmount: undefined,
    taxAmount: undefined,
    additionalLines: false,
    quantity: undefined,

    ...(action[0] === "edit" && action[1]),
  });

  const onSearchService = async (query: any) => {
    let timeout;
    if (query) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        setQuery(query);
      }, 1000);
    } else {
      setQuery("");
    }
  };

  const getVariant = async (id: string, setFieldValue: any) => {
    let res = null;
    try {
      res = variantList.find((item: any) => item.id === id);

      setFieldValue("id", res?.id);
      setFieldValue("variantId", res?.id);
      setFieldValue("refCode", res?.refCode);
      setFieldValue("skuCode", res?.skuCode);
      setFieldValue("barCode", res?.barCode);
      setFieldValue("erpCode", res?.erpCode);
      setFieldValue("nameMon", res?.nameMon);
      setFieldValue("nameEng", res?.nameEng);
      setFieldValue("nameBill", res?.nameBill);
      setFieldValue("nameWeb", res?.nameWeb);
      setFieldValue("nameApp", res?.nameApp);

      setFieldValue("image", res?.image);
      setFieldValue("hasVat", res?.hasVat);
      setFieldValue("vatPercent", res?.vatPercent);
      setFieldValue("hasTax", res?.hasTax);
      setFieldValue("taxPercent", res?.taxPercent);
      setFieldValue("category", res?.category);
      setFieldValue("supplier", res?.supplier);
      setFieldValue("brand", res?.brand);
      setFieldValue("price", res?.price);
      setFieldValue("priceStartDate", res?.priceStartDate);
      setFieldValue("unit", res?.unit);

      setFieldValue("vatAmount", res?.vatAmount);
      setFieldValue("taxAmount", res?.taxAmount);
      setFieldValue("additionalLines", false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    if (action[1]?.lines?.some((item: any) => item.id === values.id)) {
      message.error("Энэхүү бүтээгдэхүүн нэмэгдсэн байна.");
    } else {
      setFieldValue("lines", [...(action[1]?.lines || []), values]);
      setAction([]);
    }

    setLoading(false);
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, description, item, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group>
          <Avatar src={item?.image}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {item?.unit || "-"} / {currencyFormat(item?.price || 0)}
            </Text>
          </div>
        </Group>
      </div>
    );
  });

  return (
    <Form ref={refForm} validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {({ setFieldValue, values }) => {
        return (
          <Paper>
            <PageLayout title="Бараа нэмэх" subTitle="Бараа бүтээгдэхүүн нэмэх" extra={[<CloseButton onClick={() => setAction([])} key={1}></CloseButton>]}>
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <SelectField
                    SelectItem={SelectItem}
                    searchable
                    clearable
                    name="variantId"
                    placeholder="Бараа, ажил үйлчилгээ"
                    label="Бараа, ажил үйлчилгээ"
                    onSearchChange={(e) => onSearchService(e)}
                    onChange={(e: any) => {
                      setFieldValue("quantity", 0);
                      getVariant(e, setFieldValue);
                    }}
                    options={variantList.map((item: any) => {
                      return {
                        label: `${item.nameMon} ${item.optionValues.length > 0 ? " - " + item.optionValues.map((op: any) => op.name) : ""}`,
                        value: item.id,
                        item: item,
                      };
                    })}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <NumberField min={0} name="quantity" placeholder="Тоо хэмжээ" label="Тоо хэмжээ" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Input.Wrapper label={`Нэгжийн үнэ  /${values?.unit || "ширхэг"}/`}>
                    <Flex align={"center"} h="36px">
                      <Text fz={"sm"} fw={500} color="brand">
                        {currencyFormat(values?.price || 0)}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Input.Wrapper label={`Нийт үнэ`}>
                    <Flex align={"center"} h="36px">
                      <Text fz={"sm"} fw={500} color="brand">
                        {currencyFormat((values?.price || 0) * (values.quantity || 0))}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Group align="right">
                <Button
                  variant="default"
                  onClick={() => {
                    setAction([]);
                  }}>
                  Болих
                </Button>
                <Button
                  loading={loading}
                  onClick={async () => {
                    let res = await refForm.current.submit();
                    if (res) onSubmit(res);
                  }}>
                  Болсон
                </Button>
              </Group>
            </PageLayout>
          </Paper>
        );
      }}
    </Form>
  );
}
