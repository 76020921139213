import { Group, Paper, Space, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useScreenDetector } from "../screen-detector";

type IFilter = {
  date: string;
  confirmedStartDate: string;
  confirmedEndDate: string;
  pendingStartDate: string;
  pendingEndDate: string;
  categoryId: string;
};

export function PendingInvoiceChart({ data, filter, setFilter }: { data: any[]; filter: IFilter; setFilter: React.Dispatch<React.SetStateAction<IFilter>> }) {
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();

  const getOptions = () => ({
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
      align: "bottom",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: data?.map((item: any) => {
          return item.date;
        }),
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          formatter: function (this: any): string {
            return new Intl.NumberFormat("mn-MN", {
              style: "currency",
              currency: "MNT",
            }).format(this.value);
          },
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          formatter: function (this: any): string {
            return new Intl.NumberFormat("mn-MN", {
              style: "currency",
              currency: "MNT",
            }).format(this.value);
          },
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      y: 20,
    },
    series: [
      {
        name: "Нэхэмжлэлийн дүн",
        color: theme.colors.brand[2],
        type: "column",
        data: data?.map((item: any) => {
          return item.pendingAmount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Нэхэмжлэлийн тоо",
        type: "column",
        color: theme.colors.brand[6],
        data: data?.map((item: any) => {
          return item.pendingCount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  });

  return (
    <Paper withBorder py={"xs"}>
      <Group align="apart">
        <Group>
          <div className={classes.grdnt}></div>
          <Text fw={600}>Төлбөр хүлээж буй нэхэмжлэх</Text>
        </Group>
        <DatePickerInput
          type="range"
          excludeDate={(date) => {
            return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
          }}
          value={[new Date(filter.pendingStartDate), new Date(filter.pendingEndDate)]}
          onChange={(e: any) => {
            if (e[0] && e[1]) {
              setFilter({
                ...filter,
                pendingStartDate: e[0],
                pendingEndDate: e[1],
              });
            } else if (!e[0] && !e[1]) {
              setFilter({
                ...filter,
                pendingStartDate: dayjs(new Date()).subtract(14, "day").format(),
                pendingEndDate: dayjs(new Date()).format(),
              });
            }
          }}
          style={{ borderRadius: isMobile ? 0 : "5px" }}
          variant="unstyled"
          bg={theme.colors.brand[0]}
          miw={isMobile ? "100%" : "240px"}
          placeholder="Огноо хайх"
          leftSection={<IconCalendar size={"1.2rem"} />}
          valueFormat="YYYY-MM-DD"
          mr={isMobile ? 0 : "sm"}
          disabled
        />
      </Group>
      <Space h={"md"} />
      <Paper px={"lg"}>
        <HighchartsReact highcharts={Highcharts} options={getOptions()} />
      </Paper>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  grdnt: {
    background: theme.colors.brand[3],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
}));
